import "./App.css";
import "../src/css/style.css";
import "../src/fonts/stylesheet.css";
import React, { useContext, useEffect } from "react";
import Dashboard from "./pages/Dashboard";
import Landlord from "./pages/Landlord";
import PotentialLandlord from "./pages/PotentialLandlord";
import Tenants from "./pages/Tenants";
import Offers from "./pages/Offers";
import { Route, Routes, Navigate } from "react-router-dom";
import DashboardLayout from "./components/Layout/DashboardLayout";
import AuthLayout from "./components/Layout/AuthLayout";
import Login from "./pages/Login";
import { Ucontext } from "./context/Ucontext";
import TableLayout from "./components/Layout/TableLayout";
import LandLordDetail from "./pages/LandLordDetail";
// import { SnackbarProvider } from 'material-ui-snackbar-provider'
import toast, { Toaster } from "react-hot-toast";
import RentedProperties from "./pages/RentedProperties";
import UnRentedProperties from "./pages/UnRentedProperties";
import NonTenants from "./pages/NonTenants";
import UserScoreProfile from "./pages/UserScoreProfile";
import Waitlist from "./pages/Waitlist";
import TransactionsRental from "./pages/TrasactionsRental";
import TransactionsAssigned from "./pages/TransactionAssigned";
import AddAdmin from "./pages/AddAdmin";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TranslationList from "./pages/TranslationList";
import Reports from "./pages/Reports";

function App() {
  const { authState, authDispatch, login, logout, loadUser } =
    useContext(Ucontext);
  const { ustate, isLoggedIn } = authState;

  useEffect(() => {
    console.log("current auth state", authState);
    loadUser();
    //authDispatch({type:"LOGOUT",payload:"logged out"});
  }, []);

  return (
    <>
      {/*      
        <Routes >
          <Route element={<DashboardLayout />}>
            <Route path="/" element={<Dashboard />} />
            <Route path="/landlord" element={<Landlord />} />
            <Route path="/tenants" element={<Tenants />} />
            <Route path="/users" element={<Users />} />
            <Route path="/rentprop" element={<Rentedproperties />} />
            <Route path="/unrentprop" element={<Unrentedproperties />} />
            <Route path="/offers" element={<Offers />} />
          </Route>
          <Route element={<AuthLayout />}>
            <Route path="/login" element={<Login />} />
          </Route>
        </Routes> */}

      {/* <SnackbarProvider SnackbarProps={{ autoHideDuration: 2000 }}> */}
      <Toaster />
      <ToastContainer />
      <Routes>
        {!isLoggedIn ? (
          <>
            <Route element={<AuthLayout />}>
              <Route path="/login" element={<Login />} />
            </Route>
          </>
        ) : (
          <></>
        )}
        {/* nested routing */}

        <Route element={<DashboardLayout />}>
          <Route path="/" element={<Dashboard />} />
          <Route path="/offers" element={<Offers />} />
        </Route>

        <Route element={<TableLayout />}>
          <Route path="/landlord" element={<Landlord />} />
          <Route path="/tenants" element={<Tenants />} />
          <Route path="/not-tenants" element={<NonTenants />} />
          <Route path="/rentprop" element={<RentedProperties />} />
          <Route path="/unrentprop" element={<UnRentedProperties />} />
          <Route path="/scoreProfile" element={<UserScoreProfile />} />
          <Route path="/waitlist" element={<Waitlist />} />
          <Route path="/add/admin" element={<AddAdmin />} />
          <Route path="/reports" element={<Reports />} />
          <Route path="/potential/landlords" element={<PotentialLandlord />} />
          <Route
            path="/transactions/rentalInfo"
            element={<TransactionsRental />}
          />
          <Route
            path="/transactions/assigned"
            element={<TransactionsAssigned />}
          />

          <Route
            path="/landlorddetail/:landlordid"
            element={<LandLordDetail />}
          />
          <Route path="/tenantdetail/:tenantid" element={<LandLordDetail />} />
          <Route path="/translation" element={<TranslationList />} />
        </Route>

        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
      {/* </SnackbarProvider> */}
    </>
  );
}

export default App;
