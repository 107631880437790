import axios from "axios";

// const authWrapper = (logout = () => {}) => {
const axiosInstance = axios.create({
  headers: {
    "x-access-token": localStorage.getItem("token") || "",
  },
});

axiosInstance.interceptors.request.use((req) => {
  const token = localStorage.getItem("token") || "";
  if (token) {
    req.headers["x-access-token"] = token || "";
  }
  return req;
});

axiosInstance.interceptors.response.use(
  (response) => {
    // You can log the response or modify it here
    console.log("interceptors response", response);
    return response;
  },
  (error) => {
    // Handle error globally
    console.error("Error response:", error);

    if (error.response.status.toString() === "403") {
      console.log("IN 403 Error");
      localStorage.removeItem("token");
      localStorage.removeItem("saveduserid");
      localStorage.removeItem("is_verified");
      window.location.reload();
      //   logout();
    }
    return Promise.reject(error);
  }
);

//   return axiosInstance;
// };

// export default authWrapper;
export default axiosInstance;
