import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";
import { useEffect, useRef } from "react";
const Reports = () => {
  return (
    // <PowerBIEmbed
    //   embedConfig={{
    //     type: "report", // Supported types: report, dashboard, tile, visual, qna and paginated report
    //     id: undefined,
    //     embedUrl: undefined,
    //     accessToken: undefined, // Keep as empty string, null or undefined
    //     tokenType: models.TokenType.Embed,
    //   }}
    // />

    <iframe
      title="Tink Income & Expenses (1)"
      width="1140"
      height="541.25"
      src="https://app.powerbi.com/reportEmbed?reportId=ca3f0e28-d414-4c60-883e-3b5af787b079&autoAuth=true&embeddedDemo=true"
      frameborder="0"
      allowFullScreen="true"
    ></iframe>
  );
};

export default Reports;
