import React, { useReducer, useEffect } from "react";
import axios from "axios";
import { BASE_URL } from "../config";
import { Link, useNavigate } from "react-router-dom";

import { login, logout } from "./functions/AuthFunctions";

import {
  getAllTenants,
  getAllLandlords,
  getAllProperties,
  getAllPropertyDetails,
  getAllOffers,
  getFeedbackDetails,
  getLandlordProperties,
  getLandlordPropertyDetails,
  getSingleUser,
  createOffer,
  editOffer,
  deleteOffer,
  loadTransactions,
  updateUserInfo,
  getTenantJoinedFromList,
} from "./functions/AdminFunction";

import { authReducer, initialAuthState } from "./reducers/AuthReducer";
import { adminReducer, initialAdminState } from "./reducers/AdminReducer";
import axiosInstance from "../services/authWrapper";

export const Ucontext = React.createContext();

export const Uprovider = (props) => {
  const navigate = useNavigate();
  const [authState, authDispatch] = useReducer(authReducer, initialAuthState);
  const [adminState, adminDispatch] = useReducer(
    adminReducer,
    initialAdminState
  );

  useEffect(() => {
    loadUser();
  }, []);

  const loadUser = async () => {
    try {
      let token = localStorage.getItem("token");
      if (!token) {
        console.log("No token found logging out");
        authDispatch({ type: "LOGOUT" });
        navigate("/login");
        return false;
      }
      console.log("loadUser admin runs");
      const axiosRes = await axiosInstance({
        method: "GET",
        headers: { "x-access-token": localStorage.getItem("token") },
        url: `${BASE_URL}/users/protect?type=admin`,
      });
      if (axiosRes.data) {
        console.log("loadUser [SUCCESS]", axiosRes.data);
        authDispatch({ type: "ADMIN_LOADED", payload: axiosRes.data });
      } else {
        authDispatch({ type: "LOGOUT" });
        navigate("/login");
      }
      // return axiosRes?.data;
    } catch (err) {
      authDispatch({ type: "LOGOUT" });
      navigate("/login");
      console.log("loadUser [ERROR]", err);
    }
  };

  return (
    <Ucontext.Provider
      value={{
        // auth Reducer state
        authState,
        authDispatch,
        // auth global functions
        login,
        logout,
        loadUser,

        // admin Reducer state
        adminState,
        adminDispatch,
        //admin global functions

        getAllTenants,
        getAllLandlords,
        getAllProperties,
        getAllPropertyDetails,
        getAllOffers,
        getFeedbackDetails,
        getLandlordProperties,
        getLandlordPropertyDetails,
        getSingleUser,
        createOffer,
        editOffer,
        deleteOffer,
        loadTransactions,
        updateUserInfo,
        getTenantJoinedFromList,
      }}
    >
      {props.children}
    </Ucontext.Provider>
  );
};
