import React, { useState, useEffect, useContext } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Stack from "react-bootstrap/Stack";
import { AiOutlineUpload } from "react-icons/ai";
import { RiDeleteBin6Line, RiPencilLine } from "react-icons/ri";
import Modal from "react-bootstrap/Modal";
import { Ucontext } from "../context/Ucontext";
import toast from "react-hot-toast";
import axios from "axios";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { BASE_URL } from "../config";
import { MultiSelect } from "react-multi-select-component";
import axiosInstance from "../services/authWrapper";

let offerid = "";

const options = [
  { label: "Grapes 🍇", value: "grapes" },
  { label: "Mango 🥭", value: "mango" },
  { label: "Strawberry 🍓", value: "strawberry", disabled: true },
];

const Offers = () => {
  const { getAllOffers, createOffer, editOffer, deleteOffer } =
    useContext(Ucontext);

  const [ModalShow, setModalShow] = useState(false);
  const [offers, setOffers] = useState([]);
  const [offerImage, setOfferImage] = useState();
  const [allPostcode, setAllPostcode] = useState([]);
  const [allPostcodeOptions, setAllPostcodeOptions] = useState([]);
  const [allMuncipality, setAllMuncipality] = useState([]);
  const [allMuncipalityOptions, setAllMuncipalityOptions] = useState([]);
  const [allProvince, setAllProvince] = useState([]);
  const [allProvinceOptions, setAllProvinceOptions] = useState([]);
  const [image, setImage] = useState(null);
  const [selected, setSelected] = useState([]);

  const [offer, setOffer] = useState({
    title: "",
    subtitle: "",
    offerCoupon: "",
    amount: 0,
    photo: "",
    compName: "",
    muncipality: "",
    province: "",
    postcode: "",
  });

  useEffect(() => {
    loadOffers();
    loadPropInfo();
  }, []);

  const loadOffers = async () => {
    let totaloffers = await getAllOffers();
    if (totaloffers) {
      setOffers(totaloffers?.offers);
    }
  };

  const loadPropInfo = async () => {
    try {
      const axiosRes = await axiosInstance({
        method: "GET",
        headers: { "x-access-token": localStorage.getItem("token") },
        url: `${BASE_URL}/admin/property-info`,
      });
      setAllPostcode(axiosRes.data.success_res.allPostcode);
      setAllMuncipality(axiosRes.data.success_res.allMuncipality);
      setAllProvince(axiosRes.data.success_res.allProvince);
      setTimeout(() => {
        fillMultiSelectOptions();
      }, 2000);

      //return axiosRes.data;
    } catch (err) {
      console.log("loadPropInfo [ERROR] =>", err);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setOffer({ ...offer, [name]: value });
  };

  const handleImageUpload = (e) => {
    console.log(e.target.files[0]);
    setOfferImage(e.target.files[0]);
    setImage(URL.createObjectURL(e.target.files[0]));
  };

  const submitForm = async (e, formType) => {
    e.preventDefault();
    console.log("offer state", offer);
    const formData = new FormData();
    formData.append("offerphoto", offerImage);
    formData.append("title", offer.title);
    formData.append("subtitle", offer.subtitle);
    formData.append("offerCoupon", offer.offerCoupon);
    formData.append("compName", offer.compName);
    formData.append("amount", offer.amount);
    formData.append("muncipality", offer.muncipality);
    formData.append("province", offer.province);
    formData.append("postcode", offer.postcode);

    if (formType == "add") {
      let newoffer = await createOffer(formData);
      console.log(newoffer);
      if (newoffer) {
        toast.success(newoffer?.msg.toUpperCase());

        loadOffers();
      } else {
        console.log();
        toast.error("Offer could not be Added");
        // toast.loading('Waiting...');
        //toast("this is normal toast")
      }
    }

    if (formType == "edit") {
      console.log("editid", offerid);
      let newoffer = await editOffer(formData, offerid);
      console.log("data sent to server", formData);
      if (newoffer) {
        toast.success(newoffer?.msg.toUpperCase());
        loadOffers();
        setModalShow(false);
        offerid = "";
      } else {
        console.log();
        toast.error("Offer could not Changed  ");
        offerid = "";
        // toast.loading('Waiting...');
        //toast("this is normal toast")
      }
    }
  };

  const openEditModal = (offer) => {
    console.log("offer loaded", offer);
    offerid = offer._id;
    setOffer(offer);
    setModalShow(true);
  };

  const deleteSingleOffer = async (offerid) => {
    let offer_res = await deleteOffer(offerid);
    if (offer_res) {
      toast.success(offer_res.msg.toUpperCase());
      loadOffers();
    } else {
      toast.error("Offer deletion failed ");
    }
  };

  const fillMultiSelectOptions = () => {
    for (let singlePostCode of allPostcode) {
      console.log("single post code option is", singlePostCode);
      let postOption = { label: singlePostCode, value: singlePostCode };
      setAllPostcodeOptions([...allPostcodeOptions, postOption]);
    }
    console.log("all post code set is", allPostcodeOptions);
  };

  return (
    <>
      <div className="main_wrapper_offers">
        <Row>
          <Col md={6} className="first_col">
            <Form onSubmit={(e) => submitForm(e, "add")}>
              <img alt="preview image" height={160} width={300} src={image} />

              <Form.Group controlId="formFile" className="mb-3">
                <Form.Label>Image</Form.Label>
                <div className="image_picker">
                  <div className="center_upload">
                    <AiOutlineUpload size={25} />
                    <p>Upload image</p>
                  </div>
                  {/* <img width={100} height={100} src={`${BASE_URL}/${offer?.photo}`} /> */}

                  <input
                    type="file"
                    className="upload_overlay"
                    name="offerphoto"
                    onChange={handleImageUpload}
                  />
                </div>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicText">
                <Form.Label>Offer Title</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Title"
                  name="title"
                  onChange={handleChange}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicText">
                <Form.Label>Offer Coupon</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Offercoupon"
                  name="offerCoupon"
                  onChange={handleChange}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicText">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Description"
                  name="subtitle"
                  onChange={handleChange}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicText">
                <Form.Label>Discount</Form.Label>
                <Form.Control
                  type="Number"
                  placeholder="Enter Discount"
                  name="amount"
                  onChange={handleChange}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicText">
                <Form.Label>Offer/ Brand Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Name"
                  name="compName"
                  onChange={handleChange}
                />
              </Form.Group>

              <Form.Group>
                <Form.Label>Muncipality</Form.Label>
                <Form.Select
                  aria-label="Select Province"
                  name="muncipality"
                  onChange={handleChange}
                >
                  <option>Select Muncipality</option>
                  {allMuncipality &&
                    allMuncipality.map((muncipality) => (
                      <option value={muncipality}>{muncipality}</option>
                    ))}
                </Form.Select>
              </Form.Group>
              {/* 

              <Form.Group>
                <Form.Label>Muncipality</Form.Label>
                <MultiSelect
                  options={options}
                  value={selected}
                  onChange={setSelected}
                  labelledBy="Select"
                />
              </Form.Group> */}

              <Form.Group>
                <Form.Label>Province</Form.Label>
                <Form.Select
                  aria-label="Select Province"
                  name="province"
                  onChange={handleChange}
                >
                  <option>Select Province</option>
                  {allProvince &&
                    allProvince.map((province) => (
                      <option value={province}>{province}</option>
                    ))}
                </Form.Select>
              </Form.Group>
              <Form.Group>
                <Form.Label>Postcode</Form.Label>
                <Form.Select
                  aria-label="Select Province"
                  name="postcode"
                  onChange={handleChange}
                >
                  <option>Select Postcode</option>
                  {allPostcode &&
                    allPostcode.map((postcode) => (
                      <option value={postcode}>{postcode}</option>
                    ))}
                </Form.Select>
              </Form.Group>

              <Button variant="primary" type="submit">
                Add Offer
              </Button>
            </Form>
          </Col>

          <Col md={6} className="second_col">
            <h5>Total Offers {offers && offers.length}</h5>
            <div
              className="stack_of_cards"
              style={{ height: "50rem", "overflow-y": "scroll" }}
            >
              <Stack gap={3}>
                {offers &&
                  offers.map((offer, index) => (
                    <>
                      <Card className="border-none rounded shadow">
                        <Card.Body>
                          <Row>
                            <Col>
                              <div className="offer_card_edit d-flex justify-content-end">
                                <button onClick={() => openEditModal(offer)}>
                                  <RiPencilLine size={20} />
                                </button>
                                <button
                                  onClick={() => deleteSingleOffer(offer._id)}
                                >
                                  <RiDeleteBin6Line size={20} />
                                </button>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={6}>
                              <Card.Title>{offer?.title}</Card.Title>
                              <Card.Text>{offer?.subtitle}</Card.Text>
                            </Col>
                            <Col md={6}>
                              <div className="card_image_wrapper d-flex justify-content-end">
                                <div className="card_image ">
                                  <img
                                    width={100}
                                    height={100}
                                    src={`${BASE_URL}/${offer?.photo}`}
                                  />
                                </div>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <div className="offer_links d-flex justify-content-between">
                                <Card.Link href="#">Buy Now</Card.Link>
                                <Card.Text href="#">
                                  {offer?.compName}
                                </Card.Text>
                              </div>
                            </Col>
                          </Row>
                        </Card.Body>
                      </Card>
                    </>
                  ))}
              </Stack>
            </div>
          </Col>
        </Row>
      </div>

      <Modal
        className="offer_modal"
        show={ModalShow}
        onHide={() => setModalShow(false)}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Property History
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={(e) => submitForm(e, "edit")}>
            <Row>
              <Col md={6} className="modal_image offer_modal_form">
                <img alt="preview image" height={160} width={300} src={image} />
                {/* 
            <Form.Group controlId="formFile" className="mb-3">

                <Form.Label>Image</Form.Label>
                <div className='image_picker'>
                  <div className='center_upload'>
                    <AiOutlineUpload size={25} />
                    <p>Upload image</p>
                  </div>
                  <input type='file' className='upload_overlay' name="offerphoto" onChange={handleImageUpload}/>
                </div>
              </Form.Group> */}
                <input
                  type="file"
                  className="upload_overlay"
                  name="offerphoto"
                  onChange={handleImageUpload}
                />

                <Form.Group className="mb-3" controlId="formBasicText">
                  <Form.Label>Offer Title</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Title"
                    name="title"
                    value={offer.title || ""}
                    onChange={handleChange}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicText">
                  <Form.Label>Offer Coupon</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Offercoupon"
                    name="offerCoupon"
                    value={offer.offerCoupon || ""}
                    onChange={handleChange}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicText">
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Description"
                    name="subtitle"
                    value={offer.subtitle || ""}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>

              <Col md={6} className="offer_modal_form">
                <div>
                  <Form.Group className="mb-3" controlId="formBasicText">
                    <Form.Label>Discount</Form.Label>
                    <Form.Control
                      type="Number"
                      placeholder="Enter Discount"
                      name="amount"
                      value={offer.amount || 0}
                      onChange={handleChange}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicText">
                    <Form.Label>Offer/ Brand Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Name"
                      name="compName"
                      value={offer.compName || ""}
                      onChange={handleChange}
                    />
                  </Form.Group>

                  <Form.Group>
                    <Form.Label>Muncipality</Form.Label>
                    <Form.Select
                      aria-label="Select Province"
                      name="muncipality"
                      value={offer.muncipality || ""}
                      onChange={handleChange}
                    >
                      <option>Select Muncipality</option>
                      {allMuncipality &&
                        allMuncipality.map((muncipality) => (
                          <option value={muncipality}>{muncipality}</option>
                        ))}
                    </Form.Select>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Province</Form.Label>
                    <Form.Select
                      aria-label="Select Province"
                      name="province"
                      value={offer.province || ""}
                      onChange={handleChange}
                    >
                      <option>Select Province</option>
                      {allProvince &&
                        allProvince.map((province) => (
                          <option value={province}>{province}</option>
                        ))}
                    </Form.Select>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Postcode</Form.Label>
                    <Form.Select
                      aria-label="Select Province"
                      name="postcode"
                      value={offer.postcode || ""}
                      onChange={handleChange}
                    >
                      <option value="null">Select Postcode</option>
                      {allPostcode &&
                        allPostcode.map((postcode) => (
                          <option value={postcode}>{postcode}</option>
                        ))}
                    </Form.Select>
                  </Form.Group>
                </div>
              </Col>
              <Row className="offer_button">
                <Button className="mb-3" variant="primary" type="submit">
                  Update Offer
                </Button>
              </Row>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Offers;
