//Backend URL:

/**Local */
// export const BASE_URL = "http://localhost:3200";
// export const REACT_APP_URL = "http://localhost:3001";
/**Dev */
export const BASE_URL = "https://dev-api.skortorent.com";
export const REACT_APP_URL = "https://dev.skortorent.com";
/**Live */
// export const BASE_URL = "https://api.skortorent.com";
// export const REACT_APP_URL = "https://app.skortorent.com";

//Stagging
//export const BASE_URL = "https://uno.dev.seraphic.io/api";
// export const BASE_URL = "https://api.unorenting.com"

//Live
// export const BASE_URL = "http://54.164.159.66:4000";
// export const BASE_URL = "https://api.dygres.com";
