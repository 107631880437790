import React from 'react'
import "./authenticator.css"
import { Button } from 'react-bootstrap'

const Authenticator = ({ MFAsourceCode, handleChange, errorValidateMessage, otp, isQrVisible, handleSubmitOtp, isLoading, email, ...props }) => {
    return (

        <div className=" ">

            <h1 className="authenticator mb-3">Authenticator Code</h1>
            {isQrVisible ?
                <>
                    <img alt="qrcode" className="js-qrcode qrcode" src={MFAsourceCode} />
                    <div className="scan">
                        Scan with <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en_IN&gl=US" rel='noopener noreferrer' target="_blank">Authenticator App</a>
                    </div>
                </>
                :
                null
            }

            <div className="col-lg-12 d-flex flex-column authText">
                <span>Please refer to your Authenticator and copy code from <span className='authEmail'>{email}</span></span>

            </div>

            <div className="verify">
                <div className="col-lg-12">

                    <input
                        title="Email Address*"
                        className="form-control user-auth"
                        type="number"
                        // className="form-control custom-input"
                        placeholder="Enter Code*"
                        name="otp"
                        value={otp}
                        onChange={handleChange}
                    />
                    <span className="error-message-text error-text">{errorValidateMessage?.["otp"]}              </span>
                </div>
                <div className="col-lg-12 d-flex justify-content-center">
                    <Button disabled={isLoading} className="btn-fill" onClick={handleSubmitOtp}>
                        Submit Code
                        {isLoading ? "Loading..." : null}
                    </Button>


                </div>
            </div>
        </div>
    )
}

export default Authenticator