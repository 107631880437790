import React, { useContext, useState } from "react";
import { Ucontext } from "../context/Ucontext";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/esm/Container";
import { BsLinkedin } from "react-icons/bs";
import toast from "react-hot-toast";
import Authenticator from "../components/auth/Authenticator";
import axios from "axios";
import { BASE_URL } from "../config";
const Login = () => {
  const { authState, authDispatch, login, logout } = useContext(Ucontext);
  const { ustate, isLoggedIn } = authState;

  const [admin, setAdmin] = useState({
    email: "",
    password: "",
    type: "admin",
  });

  const [authRes, setAuthRes] = useState(null);
  const [authenticator, setAuthenticator] = useState({
    isLoading: false,
    isMFAisAcrive: false,
    MFAsourceCode: null,
    errorValidateMessage: "",
    otp: "",
    isQrVisible: false,
    email: null,
  });

  const onChange = (e) => {
    const { name, value } = e.target;
    setAdmin({ ...admin, [name]: value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const loginRes = await login(admin);
    if (loginRes?.auth) {
      //call api to get QR code
      setAuthRes(loginRes);
      const qrResponse = await axios({
        method: "POST",
        headers: { "x-access-token": localStorage.getItem("token") },
        url: `${BASE_URL}/admin/loginWithGoogleAuth`,
        data: {
          email: loginRes.user.email
        },
      });

      if (qrResponse) {
        const data = qrResponse.data;
        const { qrCode } = data?.success_res;
        setAuthenticator((prev) => {
          return {
            ...prev,
            isMFAisAcrive: true,
            MFAsourceCode: qrCode,
            email: loginRes.user.email,
            isLoading: false,
            isQrVisible: qrCode ? true : false,
          }
        })
      }
      // localStorage.setItem("token", loginRes.token);
      // authDispatch({ type: "LOGIN", payload: loginRes.user });
      // toast.success("Logged In successfully");
    } else {
      if (loginRes?.response?.data) {
        toast.error(loginRes?.response?.data?.message);
      } else {
        toast.error(loginRes?.message);
      }
    }
  };

  const handleSubmitOTP = async (event) => {
    event.preventDefault();

    const response = await axios({
      method: "POST",
      headers: { "x-access-token": localStorage.getItem("token") },
      url: `${BASE_URL}/admin/verifyGoogleAuthKey`,
      data: {
        otp: authenticator.otp,
        email: authRes.user.email
      },
    });

    if (response) {
      const data = response.data;
      let success_res = data?.success_res;
      if (success_res === null) {
        toast.error("Invalid Authenticator Code");
      }
      else {
        if (success_res.delta === -1) {
          toast.error("Invalid Authenticator Cod");
        }
        else {
          localStorage.setItem("token", authRes.token);
          authDispatch({ type: "LOGIN", payload: authRes.user });
          toast.success("Logged In successfully");
        }
      }
    }

  }

  return (
    <>
      <div className="Auth-wrapper">
        <div className="Auth-bar">
          <div className="logobarr">
            <Container className="p-0 d-flex justify-content-start align-items-top gap-10">
              <img src="/images/logo.svg" alt="logo" />
              <h1 className="mb-0">Admin</h1>
            </Container>
          </div>

          <div className="authbar">
            <Container>
              <div className="authbar-inner">
                <div className="leftbar">
                  <h2>Let’s get started!</h2>
                </div>
                <div className="rightbar">
                  <div className="right-contentbar">
                    {
                      !authenticator.isMFAisAcrive &&
                      <>
                        <h1>Log In</h1>
                        <form onSubmit={onSubmit}>
                          <div className="auth-form">
                            <input
                              type="text"
                              className="form-control user-auth"
                              name="email"
                              placeholder="Email"
                              onChange={onChange}
                            />
                            <input
                              type="password"
                              className="form-control user-auth border-0"
                              name="password"
                              placeholder="Password"
                              onChange={onChange}
                            />
                            <Button className="btn-fill" type="submit">
                              Login
                            </Button>
                          </div>
                        </form>
                      </>
                    }
                    {
                      authenticator.isMFAisAcrive &&
                      <Authenticator
                        {...authenticator}
                        handleChange={(event) => {
                          setAuthenticator((prev) => {
                            return {
                              ...prev,
                              otp: event.target.value,
                            }
                          })
                        }}
                        handleSubmitOtp={handleSubmitOTP}
                      />
                    }
                  </div>
                  <img
                    className="logo-img"
                    src="../images/logo.png"
                    alt="logo"
                  />
                </div>
              </div>
            </Container>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
