import React, { useState, useEffect, useContext } from "react";
import Card from "react-bootstrap/Card";
import Usr from "../icons/ic1.png";
import Usr2 from "../icons/ic2.png";
import Usr3 from "../icons/ic3.png";
import Usr4 from "../icons/ic4.png";
import { BASE_URL } from "../config/index";
import axios from "axios";
import GraphData from "./GraphData";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Ucontext } from "../context/Ucontext";
import Loading from "../components/loader/Loading";
import axiosInstance from "../services/authWrapper";

const Dashboard = () => {
  const {
    adminState,
    adminDispatch,
    getAllLandlords,
    getAllOffers,
    getAllTenants,
    getAllProperties,
  } = useContext(Ucontext);
  const { totalTenants, totalLandlords, totalProperties, totalOffers } =
    adminState;

  const [graphdata, setGraphData] = useState({});
  const [loading, setLoading] = useState(true);
  const [durationType, setDurationType] = useState("daily");

  useEffect(() => {
    loadGraphData(durationType);
  }, [durationType]);
  //all api requests

  const loadSummary = async () => {
    let tenants = await getAllTenants();
    if (tenants.status == "Success") {
      adminDispatch({ type: "TENANTS_LOADED", payload: tenants?.tenants });
    }
    let landlords = await getAllLandlords();
    if (landlords.status == "success") {
      adminDispatch({
        type: "LANDLORDS_LOADED",
        payload: landlords?.landlords,
      });
    }
    let offers = await getAllOffers();
    if (offers.status == "success") {
      adminDispatch({ type: "OFFERS_LOADED", payload: offers?.offers });
    }
    let properties = await getAllProperties();
    if (properties.status == "success") {
      adminDispatch({
        type: "PROPERTIES_LOADED",
        payload: properties?.properties,
      });
    }
  };

  const loadGraphData = async (durationType) => {
    try {
      setLoading(true);
      const axiosRes = await axiosInstance({
        method: "GET",
        headers: { "x-access-token": localStorage.getItem("token") },
        url: `${BASE_URL}/admin/graphdata?type=${durationType}`,
      });
      console.log("loadGraphData [SUCCESS]", axiosRes.data);
      setLoading(false);
      if (axiosRes.data.success) {
        setGraphData(axiosRes.data.success_res);
      }
    } catch (err) {
      setLoading(false);
      console.log("loadGraphData [ERROR] =>", err);
    }
  };

  return (
    <>
      {loading && <Loading />}
      <div className="cardcontainer">
        <Row gap={5}>
          <Col md={4} sm={12}>
            <Card>
              <Card.Body>
                <Row>
                  <Col md={3}>
                    <img src={Usr} height={54} alt="/" />
                  </Col>
                  <Col md={9}>
                    <div className="text_data">
                      <p>Rent Paying Tenant</p>
                      <h1>{graphdata.totalRentTenantCount}</h1>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4} sm={12}>
            <Card>
              <Card.Body>
                <Row>
                  <Col md={3}>
                    <img src={Usr} height={54} alt="/" />
                  </Col>
                  <Col md={9}>
                    <div className="text_data">
                      <p>Non-Rent Paying Tenant</p>
                      <h1>{graphdata.totalNonRentTenantCount}</h1>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>{" "}
          <Col md={4} sm={12}>
            <Card>
              <Card.Body>
                <Row>
                  <Col md={3}>
                    <img src={Usr2} height={54} alt="/" />
                  </Col>
                  <Col md={9}>
                    <div className="text_data">
                      <p>Landlord/ Agents</p>
                      <h1>{graphdata?.totalLandlordCount}</h1>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
      {!loading && (
        <GraphData
          graphState={graphdata}
          onDurationChange={setDurationType}
          durationType={durationType}
        />
      )}
    </>
  );
};

export default Dashboard;
